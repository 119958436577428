import React, { Fragment, useEffect } from 'react'
import tree from './util/tree'
import bem from 'bem'
import css from './tableOfContents.module.scss'
import WithClassName from 'containers/WithClassName'

const b = bem.toc(css)

const Inner = ({ children, className, offset = 0 }) => {
	const scroll = (e, id) => {
		e.preventDefault()
		const element = document.getElementById(id)
		if (element)
			window.scroll({
				top: element.offsetTop - offset,
				left: 0,
				behavior: 'smooth',
			})
	}

	const Links = ({ trees }) => {
		return trees.map(({ id, name, child, tag }) => (
			<div key={id} className={b('link', { tag }).toString()}>
				<a href={`#${id}`} title={`Перейти к абзацу: «${name}»`} onClick={e => scroll(e, id)}>
					{name}
				</a>
				{!!child.length && <Links trees={child} />}
			</div>
		))
	}

	let toc

	if (children) {
		const trees = tree(children)
		if (trees.length) toc = <Links trees={trees} />
	}

	// Прокрутка страницы до элемента из хеша
	useEffect(() => {
		let timer
		const hash = window.location.hash
		if (hash && children) {
			const element = document.getElementById(hash.substring(1))

			// Если элемент найден и человек не прокручивал страницу
			if (element && window.scrollY === 0) window.scrollTo({ top: element.offsetTop - 10 })
		}
		return () => clearTimeout(timer)
	}, [children])

	if (!toc) return null

	return (
		<div className={b({}, className)}>
			<h2 className="h5 mb-sm">Содержание</h2>
			<div className={b('data')}>{toc}</div>
		</div>
	)
}

const TableOfContents = ({ id, ...rest }) => (
	<Fragment key={id}>
		<Inner {...rest} />
	</Fragment>
)

export default WithClassName(TableOfContents)
