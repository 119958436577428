import React, { useRef } from 'react'
import bem from 'bem'
import css from './Post.module.scss'
import { default as Layout } from 'layouts/Main'
import Content from 'components/Content'
import SEO from 'components/SEO'
import Breadcrumbs from 'components/Breadcrumbs'
import SchemaBlogPosting from 'components/Schema/BlogPosting'
import WithLocation from 'containers/WithLocation'
import Tags from 'components/Tags'
import setIds from 'components/TableOfContents/util/setIds'
import TableOfContents from 'components/TableOfContents'
import EntryShort from 'components/Post/EntryShort'
import Share from 'components/Share'
// import Subscribe from 'components/Subscribe'
import Author from 'components/Author'
import CommentList from 'components/CommentList'
import CommentForm from 'components/CommentForm'
import FAQ from 'components/FAQ'
import addAfterParagraph from 'utils/addAfterParagraph'
import useIsSticky from 'hooks/useIsSticky'
import Link from 'gatsby-link'
import LazySizes from 'components/LazySizes'
import Img from 'gatsby-image'
import useSiteMetadata from 'hooks/useSiteMetadata'

const b = bem.scenePost(css)
const sidebarPostsCount = 4
const bottomPostsCount = 4

// const GoogleBlock = () => {
// 	return (
// 		<p className="no-last" style={{ background: '#eee' }}>
// 			Separator
// 		</p>
// 	)
// }
const GoogleBlock = () => {
	return null
}

const Post = props => {
	const { location, pageContext: post } = props
	const { title, excerpt, content: _content, seo, categories, featuredImage, relative, faq } = post
	const { siteUrl } = useSiteMetadata()
	const category = categories.nodes[0]
	const image = featuredImage ? featuredImage.imageFile : null
	const content = !faq ? _content : _content + '<h2>Часто задаваемые вопросы</h2>'

	let contentWithIds = setIds(content)

	const sidebarPosts = relative.slice(0, sidebarPostsCount)
	const bottomPosts = relative.slice(sidebarPostsCount, sidebarPostsCount + bottomPostsCount)

	// Сервер или клиент
	const isClient = typeof window !== 'undefined'

	// Использовать ли стики для сайдбара (offset = высота header + margin)
	const sidebarRef = useRef(null)
	const isSidebarSticky = useIsSticky(sidebarRef, 120)

	// Текст статьи с ключением
	// 1. Вставки в текст
	// 2. Списка подобных постов
	const getText = () => {
		let text = contentWithIds

		// TODO: Вставки в текст
		for (let i = 0; i < 10; i++) {
			text = addAfterParagraph(text, 2 + i * 3, <GoogleBlock />)
		}

		// Список подобных постов
		const separator = '__RELATED_LIST__'
		text = addAfterParagraph(text, 1, separator)
		const isList = text.indexOf(separator) !== -1

		if (!isList) return <div dangerouslySetInnerHTML={{ __html: text }} />

		const list = () => {
			if (relative.length <= sidebarPostsCount) return null
			const posts = relative.slice(sidebarPostsCount, sidebarPostsCount + 6)
			return (
				<div className="text-sm m">
					<h2 className="h5 mb-sm">Смотрите также</h2>
					<ul>
						{posts.map(post => (
							<li key={`post-relative-list-${post.postId}`}>
								<Link to={post.url} title={post.title}>
									{post.title}
								</Link>
							</li>
						))}
					</ul>
				</div>
			)
		}
		const parts = text.split(separator)
		return (
			<>
				<div className="p-no-last" dangerouslySetInnerHTML={{ __html: parts[0] }} />
				{list()}
				<div dangerouslySetInnerHTML={{ __html: parts[1] }} />
			</>
		)
	}

	const thumbnail = featuredImage.imageFile.childImageSharp.fluid || {}
	const openGraph = {
		...(thumbnail && {
			image: {
				url: siteUrl + thumbnail.src,
				width: thumbnail.presentationWidth,
				height: thumbnail.presentationHeight,
			},
		}),
	}

	return (
		<Layout className={b()}>
			<LazySizes />
			<SchemaBlogPosting {...post} />
			<SEO title={seo.title} description={seo.description} og={openGraph} />
			{image && <Img className={b('preview')} fluid={image.childImageSharp.fluid} alt={title} />}
			<Content className={b('inner')}>
				<Breadcrumbs
					className="mb"
					pages={[
						{ url: '/', title: 'Главная' },
						{ url: category.url, title: category.name },
						{ url: location.pathname, title },
					]}
				/>
				<div className={b('body')}>
					<div className={b('content')}>
						<h1>{title}</h1>
						<div className="mb">
							<Tags items={categories.nodes.map(({ categoryId: key, name, url }) => ({ key, name, url }))} />
						</div>

						<div className="mb">
							<GoogleBlock />
						</div>

						<TableOfContents className={b('content-toc')} offset={10}>
							{contentWithIds}
						</TableOfContents>

						<div className="hidden-md hidden-lg hidden-xl">
							<GoogleBlock />
						</div>

						{excerpt && <div className={b('excerpt')} dangerouslySetInnerHTML={{ __html: excerpt }} />}

						{/* Текст статьи */}
						<div className={b('text', 'static-text mb-lg')}>
							{getText()}
							{faq && <FAQ currentIndexes={[0]} items={faq} isNumbers={true} />}
						</div>

						{/*<div className="mb-lg">*/}
						{/*	<Subscribe buttonText="Подписаться" />*/}
						{/*</div>*/}

						<Author className="mb-lg" />

						<Share className="mb-lg" title="Расскажите друзьям в социальных сетях" titleTag="div" size={40} />

						<div className="mb-lg">
							<CommentList post={post} limit={5} />
						</div>
						<div className="mb-lg">
							<CommentForm post={post} />
						</div>

						{!!bottomPosts.length && (
							<>
								<h2>Вам может быть интересно</h2>
								<div className={b('relative-grid')}>
									<div className={b('relative-grid-inner')}>
										{bottomPosts.map(post => (
											<div key={`relative-grid-${post.postId}`} className={b('relative-grid-item')}>
												<EntryShort post={post} isExcerpt={false} isCategories={false} isCentered />
											</div>
										))}
									</div>
								</div>
							</>
						)}
					</div>

					<div className={b('sidebar')}>
						<div className={b('sticky', { active: isSidebarSticky })} ref={sidebarRef}>
							{/* Не рендерим дубликат элемента в итоговый HTML */}
							{isClient && (
								<div className={b('widget')}>
									<TableOfContents offset={100}>{contentWithIds}</TableOfContents>
								</div>
							)}

							<div className={b('widget')}>
								<GoogleBlock />
							</div>

							{!!sidebarPosts.length && (
								<div className={b('widget')}>
									<div className="text-sm">
										<h3 className="h5 mb-sm">Смотрите также</h3>
										<ul>
											{sidebarPosts.map(post => (
												<li key={`sidebar-relative-list-${post.postId}`}>
													<Link to={post.url} title={post.title}>
														{post.title}
													</Link>
												</li>
											))}
										</ul>
									</div>
								</div>
							)}

							<div className={b('widget')}>
								<GoogleBlock />
							</div>

							{/* Не рендерим дубликат элемента в итоговый HTML */}
							{/*{isClient && (*/}
							{/*	<div className={b('widget')}>*/}
							{/*		<Subscribe />*/}
							{/*	</div>*/}
							{/*)}*/}

							{isClient && (
								<div className={b('widget')}>
									<Share titleTag="h3" />
								</div>
							)}
						</div>
					</div>
				</div>
			</Content>
		</Layout>
	)
}

export default WithLocation(Post)
