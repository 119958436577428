import transliterate from 'utils/transliterate'
import getDuplicateNamePostfix from 'utils/getDuplicateNamePostfix'

/**
 * Формирование идентификаторов к тегам заголовков
 * @param text
 */
const setIds = text => {
	if (!text) return ''
	let ids = []
	return text.replace(/(<h[2-6])(.*?)(>)([^<]+)/g, (...match) => {
		let [input, tag, attributes, close, content] = match
		if (attributes.indexOf('id="') !== -1) return input
		const id = getDuplicateNamePostfix(ids, transliterate(content), '-%s')
		ids.push(id)
		attributes += ` id="${id}"`
		return [tag, attributes, close, content].join('')
	})
}

export default setIds
