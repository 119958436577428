const ReactDOMServer = require('react-dom/server')

/**
 * Замена в тексте
 * @param text
 * @param count
 * @param html
 * @returns {*}
 */
const addAfterParagraph = (text, count = 1, html) => {
	let s = text

	// 1. Временная замена переносов строк
	s = s.replace(/\n/g, '__LINE__')

	// 2. Временная замена конечных параграфов в цитатах
	s = s.replace(/<blockquote.*?>.*<\/blockquote>/g, v => {
		return v.replace(/<\/p>/g, '__P_LAST__')
	})

	// Добавление текста после параграфа
	s = s
		.split(/<\/p>/)
		.join('</p>__SEPARATE__')
		.split(/__SEPARATE__/)
	if (s[count - 1]) s[count - 1] += ReactDOMServer.renderToString(html)
	s = s.join('')

	// 1. Обратная замена переносов строк
	s = s.replace(/__LINE__/g, '\n')

	// 2. Обратная замена конечных параграфов в цитатах
	s = s.replace(/__P_LAST__/g, '</p>')

	return s
}

module.exports = addAfterParagraph
