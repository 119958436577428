import React from 'react'
import PropTypes from 'prop-types'
import { JSONLD, GenericCollection, Generic } from 'react-structured-data'
import useSiteMetadata from 'hooks/useSiteMetadata'

const SchemaBreadcrumbList = ({ pages }) => {
	const { siteUrl } = useSiteMetadata()
	return (
		<JSONLD dangerouslyExposeHtml>
			<Generic jsonldtype="BreadcrumbList">
				<GenericCollection type="itemListElement">
					{pages.map(({ title, url }, index) => (
						<Generic
							key={`breadcrumb-schema-${url}`}
							jsonldtype="ListItem"
							schema={{
								position: index + 1,
								name: title,
								item: siteUrl + url,
							}}
						/>
					))}
				</GenericCollection>
			</Generic>
		</JSONLD>
	)
}

SchemaBreadcrumbList.propTypes = {
	pages: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
		}).isRequired
	).isRequired,
}

export default SchemaBreadcrumbList
