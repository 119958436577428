import React, { useState } from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Accordion.module.scss'

const b = bem.accordion(css)

const Accordion = props => {
	const { items, currentIndexes, titleTag: TitleTag } = props
	const [openedIndexes, setOpenedIndexes] = useState(currentIndexes)

	if (!items || !items.length) return null

	const handleSetCurrent = index => {
		const indexes =
			openedIndexes.indexOf(index) !== -1 ? openedIndexes.filter(i => i !== index) : [...openedIndexes, index]
		setOpenedIndexes(indexes)
	}

	return (
		<ul className={b()}>
			{items.map(({ title, description }, index) => (
				<li key={index} className={b('item', { active: openedIndexes.indexOf(index) !== -1 })}>
					<div className={b('title')}>
						<div
							role="button"
							tabIndex={0}
							className={b('button')}
							onClick={() => handleSetCurrent(index)}
							onKeyDown={() => {}}
						>
							<span className={b('number')}>{index + 1}</span>
							<TitleTag className={b('name')} dangerouslySetInnerHTML={{ __html: title }} />
						</div>
					</div>
					<div className={b('description')}>
						<div className={b('description-inner')}>{description}</div>
					</div>
				</li>
			))}
		</ul>
	)
}

Accordion.defaultProps = {
	currentIndexes: [],
	titleTag: 'h3',
}

Accordion.propTypes = {
	currentIndexes: PropTypes.arrayOf(PropTypes.number),
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.any.isRequired,
			description: PropTypes.any.isRequired,
		}).isRequired
	),
}

export default Accordion
