import React, { useState } from 'react'
import bem from 'bem'
import css from './Comment.module.scss'
import stripTags from 'utils/stripTags'
import CommentForm from 'components/CommentForm'

// Обработка текста комментария
const format = comment => {
	// Установка конечных переносов строк
	comment = comment.replace(/(<p>.*?<\/p>)/g, '$1\n')

	// Удаление HTML-тегов
	comment = stripTags(comment)

	// Удаление последнего переноса строки
	comment = comment.replace(/\n$/g, '')

	return comment
}

const b = bem.comment(css)

const Comment = ({ comment, post, isLastDepth }) => {
	const { commentId, author, date, content } = comment
	const [isReply, setIsReply] = useState(false)

	return (
		<div className={b()}>
			<header className={b('header')}>
				<div className={b('author')}>{author.name}</div>
				<div className={b('date')}>{date}</div>
			</header>
			<div className={b('content')}>
				<div className={b('text')}>{format(content)}</div>
				{!isLastDepth && (
					<div className={b('reply-button')}>
						<button type="button" onClick={() => setIsReply(!isReply)} className="button-link link-secondary">
							{!isReply ? 'Ответить' : 'Отменить'}
						</button>
					</div>
				)}
				{!isLastDepth && isReply && (
					<div className={b('reply-form')}>
						<CommentForm post={post} parentId={commentId} isReply />
					</div>
				)}
			</div>
		</div>
	)
}

export default Comment
