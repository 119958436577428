import React, { useState } from 'react'
import bem from 'bem'
import css from './CommentList.module.scss'
import Comment from 'components/Comment'
import Button from 'components/Button'
import declension from 'utils/declension'

const b = bem.commentList(css)

const Thread = ({ comments, post, level = 0 }) => {
	if (!comments.length) return null
	const depth = level + 1
	return (
		<ul className={b('list')}>
			{comments.map(comment => {
				return (
					<li className={b('thread')} key={comment.id}>
						<Comment comment={comment} post={post} isLastDepth={depth === 5} />
						<Thread comments={comment.children.nodes} post={post} level={depth} />
					</li>
				)
			})}
		</ul>
	)
}

const CommentList = ({ post, limit }) => {
	const comments = post.comments.nodes
	const count = comments.length
	const [showedCount, setShowedCount] = useState(limit)
	const [showed, setShowed] = useState(comments.slice(0, limit))

	if (!count) return null

	const handleMore = () => {
		const next = showedCount + limit
		setShowedCount(next)
		setShowed(comments.slice(0, next))
	}

	return (
		<div className={b()}>
			<h2 className="h3 mb-sm">
				{count} {declension(count, 'комментариев', 'комментарий', 'комментария')}
			</h2>
			<Thread comments={showed} post={post} />
			{showedCount < count && (
				<Button onClick={handleMore} size="xs">
					Загрузить еще комментарии
				</Button>
			)}
		</div>
	)
}

CommentList.defaultProps = {
	limit: 5,
}

export default CommentList
