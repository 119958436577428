import lodash from '../utils/lodash'

/**
 * Получение всех изображений из текста
 * @returns {[]}
 */
const getTextImages = (props, location = {}) => {
	let { text, siteUrl } = props
	let { pathname } = location

	let images = []
	if (!text || typeof text !== 'string') return images

	// Получаем относительный путь
	// http://site.ru/page/1.html -> /page
	// http://site.ru/path/to -> /path
	// http://site.ru/page -> /
	// http://site.ru/ -> /
	pathname = pathname.replace(/\/$/, '')
	let path = pathname.split('/')
	path.pop()
	path = path.join('/')

	let regexp = /<img.*?src="([^"]+)".*?>/gi
	let match = regexp.exec(text)
	while (match) {
		let url = match[1]

		// Если ссылка на изображение относительная
		if (!/^http/.test(url)) {
			// Если изображение начинается со слеша => лежит в корне сайта
			if (url[0] === '/') url = siteUrl + url
			// Иначе отновительно текущей страницы (path)
			else url = siteUrl + path + '/' + url
		}

		images.push(url)
		match = regexp.exec(text)
	}

	images = lodash.uniq(images)

	return images
}

export default getTextImages
