import { useState, useEffect } from 'react'

/**
 * Использовать ли стики для элемента
 * Возвращает false, если элемент больше по высоте размера окна
 * @param ref - элемент
 * @param delta - приращение к высоте
 * @returns {boolean}
 */
const useIsSticky = (ref, delta = 0) => {
	const handleResize = () =>
		typeof window !== 'undefined' && ref.current ? window.innerHeight - delta >= ref.current.offsetHeight : false

	const [isSticky, setIsSticky] = useState(handleResize())

	// При изменении размеров окна проверяем стики сайдбара
	/* eslint-disable */
	useEffect(() => {
		const resize = () => {
			setIsSticky(handleResize())
		}
		resize()
		window.addEventListener('resize', resize)
		return () => window.removeEventListener('resize', resize)
	}, [])
	/* eslint-enable */

	return isSticky
}

export default useIsSticky
