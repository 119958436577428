import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Author.module.scss'
import Avatar from './img/dmitry-voronin.jpg'

const b = bem.author(css)

const Author = ({ className }) => (
	<div className={b(null, className)}>
		<div className={b('avatar')}>
			<img className={b('img')} src={Avatar} alt="Дмитрий Воронин" />
		</div>
		<div className={b('info')}>
			<div className={b('title', 'h4')}>Об авторе: Дмитрий Воронин</div>
			<div className={b('text')}>
				<p>
					<strong>
						<a
							href="https://vk.com/iparsic"
							title="Дмитрий Воронин ВКонтакте"
							target="_blank"
							rel="nofollow noopener noreferrer"
						>
							Дмитрий Воронин
						</a>
					</strong>
					&nbsp;&mdash; проджект-менеджер и&nbsp;представитель сервиса &laquo;Парсик&raquo;. Является экспертом
					в&nbsp;технологиях парсинга. Отвечает за&nbsp;качество предоставляемых услуг и&nbsp;обеспечивает
					консалтинговые услуги клиентам.
				</p>
				{/*<p>P.S. <em>&laquo;Я&nbsp;горжусь своей командой и&nbsp;нашими работой&raquo;</em></p>*/}
			</div>
		</div>
	</div>
)

Author.propTypes = {
	className: PropTypes.string,
}

export default Author
