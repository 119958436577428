/**
 * Проверка корректности дерева (на наличие h2 > h4)
 * @param tags
 */
const validate = tags => {
	if (!tags.length) return false

	let valid = true
	let old = null

	tags.map(({ tag }) => {
		// Первый тег не h2
		if (old === null && tag !== 'h2') {
			valid = false
			return null
		}

		// После h2 стоит тег h4, h5, h6
		if (old === 'h2' && ['h4', 'h5', 'h6'].indexOf(tag) !== -1) {
			valid = false
			return null
		}

		// После h3 стоит тег h5, h6
		if (old === 'h3' && ['h5', 'h6'].indexOf(tag) !== -1) {
			valid = false
			return null
		}

		// После h4 стоит тег h6
		if (old === 'h4' && ['h6'].indexOf(tag) !== -1) {
			valid = false
			return null
		}

		old = tag

		return null
	})

	return valid
}

export default validate
