import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './Breadcrumbs.module.scss'
import Link from 'gatsby-link'
import SchemaBreadcrumbList from 'components/Schema/BreadcrumbList'
import WithLocation from 'containers/WithLocation'
import Icon from 'components/Icon'
import WithClassName from 'containers/WithClassName'

const b = bem.breadcrumbs(css)

const Breadcrumbs = ({ location, pages, className }) => {
	if (!pages.length) return null
	return (
		<>
			<SchemaBreadcrumbList pages={pages} />
			<nav className={b({}, className)}>
				<ul className={b('items')}>
					{pages.map(({ url, title, icon }) => {
						return (
							<li key={`breadcrumb-${url}`} className={b('item')}>
								{location.pathname !== url ? (
									<Link to={url} title={title}>
										{icon ? <Icon type={icon} size={20} /> : title}
									</Link>
								) : (
									title
								)}
							</li>
						)
					})}
				</ul>
			</nav>
		</>
	)
}

Breadcrumbs.propTypes = {
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired,
	}).isRequired,
	pages: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			url: PropTypes.string.isRequired,
			icon: PropTypes.string,
		}).isRequired
	).isRequired,
}

export default WithLocation(WithClassName(Breadcrumbs))
