/**
 * Получение параметров заголовков
 * @param text
 */
const getParams = text => {
	let res = []
	let regexp = /<(h[2-6]).*?id="(.*?)".*?>([^<]+)/g
	let match = regexp.exec(text)
	while (match) {
		let name = match[3]

		// Удаление nbsp
		name = name.replace(/&nbsp;/g, ' ')

		// Удаление html-мнемоник
		name = name.replace(/&.*?;/g, '')

		// Удаление двойных пробелов
		name = name.replace(/\s{2,}/g, ' ')

		res.push({
			tag: match[1],
			id: match[2],
			name,
		})
		match = regexp.exec(text)
	}
	return res
}

export default getParams
