import validate from './validate'
import getParams from './getParams'

/**
 * Создание древовидного массива на оснвое заголовков h
 * @param text
 * @returns {[]}
 */
const tree = text => {
	const data = getParams(text)

	// Проверка корректности дерева
	if (!validate(data)) return []

	let res = []
	let old = null
	let [h2, h3, h4, h5, h6] = new Array(5).fill(0)

	data.map(params => {
		const { tag } = params

		const obj = (h2, h3, h4, h5, h6) => {
			const nums = [h2 + 1]
			if (h3 !== undefined) nums.push(h3 + 1)
			if (h4 !== undefined) nums.push(h4 + 1)
			if (h5 !== undefined) nums.push(h5 + 1)
			if (h6 !== undefined) nums.push(h6 + 1)
			return {
				...params,
				number: nums.join('.'),
				child: [],
			}
		}

		// Возрастание
		if (old === null && tag === 'h2') res[h2] = obj(h2)

		if (old === 'h2' && tag === 'h3') res[h2].child[h3] = obj(h2, h3)

		if (old === 'h3' && tag === 'h4') res[h2].child[h3].child[h4] = obj(h2, h3, h4)

		if (old === 'h4' && tag === 'h5') res[h2].child[h3].child[h4].child[h5] = obj(h2, h3, h4, h5)

		if (old === 'h5' && tag === 'h6') res[h2].child[h3].child[h4].child[h5].child[h6] = obj(h2, h3, h4, h5, h6)

		// Убывание
		if (['h3', 'h4', 'h5', 'h6'].indexOf(old) !== -1 && tag === 'h2') {
			h2++
			h3 = 0
			h4 = 0
			h5 = 0
			h6 = 0
			res[h2] = obj(h2)
		}
		if (['h4', 'h5', 'h6'].indexOf(old) !== -1 && tag === 'h3') {
			h3++
			h4 = 0
			h5 = 0
			h6 = 0
			res[h2].child[h3] = obj(h2, h3)
		}
		if (['h5', 'h6'].indexOf(old) !== -1 && tag === 'h4') {
			h4++
			h5 = 0
			h6 = 0
			res[h2].child[h3].child[h4] = obj(h2, h3, h4)
		}
		if (['h6'].indexOf(old) !== -1 && tag === 'h5') {
			h5++
			h6 = 0
			res[h2].child[h3].child[h4].child[h5] = obj(h2, h3, h4, h5)
		}

		// Равные
		if (old === 'h2' && tag === 'h2') {
			h2++
			res[h2] = obj(h2)
		}
		if (old === 'h3' && tag === 'h3') {
			h3++
			res[h2].child[h3] = obj(h2, h3)
		}
		if (old === 'h4' && tag === 'h4') {
			h4++
			res[h2].child[h3].child[h4] = obj(h2, h3, h4)
		}
		if (old === 'h5' && tag === 'h5') {
			h5++
			res[h2].child[h3].child[h4].child[h5] = obj(h2, h3, h4, h5)
		}
		if (old === 'h6' && tag === 'h6') {
			h6++
			res[h2].child[h3].child[h4].child[h5].child[h6] = obj(h2, h3, h4, h5, h6)
		}

		old = tag

		return null
	})

	return res
}

export default tree
