import { sprintf } from 'sprintf-js'

/**
 * Поиск дубикатов названий и добавление постфикса при их наличии
 * @param a - массив строк
 * @param s - текущая строка
 * @param format - формат постфикса
 * @returns {*}
 */
const getDuplicateNamePostfix = (a, s, format = '_%s') => {
	let i = 0
	let _s = s

	while (a.indexOf(_s) !== -1) {
		i++
		_s = s + sprintf(format, i)
	}

	return _s
}

export default getDuplicateNamePostfix
