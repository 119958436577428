/**
 * Транслит из русского в английский в соответствии с правилами Яндекса
 * @param s
 * @returns {*}
 */
const transliterate = s => {
	// Перевод в нижний регистр
	s = s.toLowerCase()

	// Замена подчеркиваний дефис
	s = s.replace(/_/g, '-')

	// Удаление лишних символов
	s = s.replace(/[^0-9a-zа-я-.\s]/gi, '')

	// Удаление двойных пробелов
	s = s.replace(/\s{2,}/g, ' ')

	// Удаление начальных и конечных пробелов
	s = s.trim()

	// Замена пробелов
	s = s.replace(/\s/g, '-')

	let ru = [
		'а',
		'б',
		'в',
		'г',
		'д',
		'е',
		'ё',
		'ж',
		'з',
		'и',
		'й',
		'к',
		'л',
		'м',
		'н',
		'о',
		'п',
		'р',
		'с',
		'т',
		'у',
		'ф',
		'х',
		'ц',
		'ч',
		'ш',
		'щ',
		'ь',
		'ы',
		'ъ',
		'э',
		'ю',
		'я',
	]
	let en = [
		'a',
		'b',
		'v',
		'g',
		'd',
		'e',
		'yo',
		'zh',
		'z',
		'i',
		'j',
		'k',
		'l',
		'm',
		'n',
		'o',
		'p',
		'r',
		's',
		't',
		'u',
		'f',
		'h',
		'c',
		'ch',
		'sh',
		'shch',
		'',
		'y',
		'',
		'eh',
		'yu',
		'ya',
	]

	ru.forEach((r, index) => {
		s = s.replace(new RegExp(r, 'gi'), en[index])
	})

	return s
}

export default transliterate
