import React from 'react'
import PropTypes from 'prop-types'
import bem from 'bem'
import css from './FAQ.module.scss'
import Accordion from 'components/Accordion'
import SchemaFAQPage from 'components/Schema/FAQPage'

const b = bem.faq(css)

const FAQ = props => {
	const { items, isNumbers, currentIndexes, titleTag } = props

	if (!items || !items.length) return null

	return (
		<div className={b()}>
			<SchemaFAQPage items={items} isNumbers={isNumbers} />
			<Accordion items={items} currentIndexes={currentIndexes} titleTag={titleTag} />
		</div>
	)
}

FAQ.defaultProps = {
	currentIndexes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
}

FAQ.propTypes = {
	isNumbers: PropTypes.bool,
	items: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			description: PropTypes.any.isRequired,
		}).isRequired
	),
}

export default FAQ
