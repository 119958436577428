import React from 'react'
import { JSONLD, Generic, GenericCollection } from 'react-structured-data'
import stripTags from 'utils/stripTags'
import ReactDOMServer from 'react-dom/server'

/**
 * Схема - FAQ
 * @param props
 * @returns {null|*}
 * @constructor
 */
const SchemaFAQPage = props => {
	const { items, isNumbers } = props
	return (
		<JSONLD dangerouslyExposeHtml>
			<Generic jsonldtype="FAQPage">
				<GenericCollection type="mainEntity">
					{items.map(({ title, description }, index) => {
						const titleCleared = title.replace(/&nbsp;/, ' ')
						return (
							<Generic
								key={`faq-schema-${index}`}
								jsonldtype="Question"
								schema={{
									name: `➤ ${isNumbers ? index + 1 + '. ' : ''}${titleCleared}`,
								}}
							>
								<Generic
									type="acceptedAnswer"
									jsonldtype="Answer"
									schema={{
										text: stripTags(ReactDOMServer.renderToString(description)),
									}}
								/>
							</Generic>
						)
					})}
				</GenericCollection>
			</Generic>
		</JSONLD>
	)
}

export default SchemaFAQPage
