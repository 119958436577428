import React from 'react'
import WithLocation from 'containers/WithLocation'
import stripTags from 'utils/stripTags'
import getTextImages from 'utils/getTextImages'
import { JSONLD, Generic } from 'react-structured-data'
import useSiteMetadata from 'hooks/useSiteMetadata'
import genericOrganization from 'components/Schema/Generic/organization'
import genericPerson from 'components/Schema/Generic/person'
import lodash from 'utils/lodash'

/**
 * Схема - Статья
 * @param props
 * @returns {null|*}
 * @constructor
 */
const SchemaBlogPosting = props => {
	const { location, title, content, excerpt, date, categories, featuredImage } = props
	const { pathname } = location
	const site = useSiteMetadata()
	const { siteUrl } = site

	const textImages = getTextImages({ text: [excerpt, content].join(' '), siteUrl }, { pathname })
	const thumbnailUrl = featuredImage ? siteUrl + featuredImage.imageFile.childImageSharp.fluid.src : ''
	if (thumbnailUrl) textImages.unshift(thumbnailUrl)
	if (!textImages.length) return null

	return (
		<JSONLD dangerouslyExposeHtml>
			<Generic
				jsonldtype="BlogPosting"
				schema={{
					dateCreated: date,
					datePublished: date,
					dateModified: date,
					headline: title,
					mainEntityOfPage: siteUrl + pathname,
					url: siteUrl + pathname,
					description: excerpt,
					articleBody: content,
					articleSection: lodash.map(categories.nodes, 'name'),
					wordCount: content ? stripTags(content).split(' ').length : 0,
					isFamilyFriendly: true,
					image: textImages,
					...(!!thumbnailUrl && { thumbnailUrl }),
				}}
			>
				{genericPerson({ type: 'author', name: site.schema.author.name, url: site.schema.author.url })}
				{genericOrganization({ site, type: 'publisher' })}
			</Generic>
		</JSONLD>
	)
}

export default WithLocation(SchemaBlogPosting)
