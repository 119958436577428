import React from 'react'
import loadable from '@loadable/component'
import './async.scss'

const Share = loadable(() => import('./Share'))

export default props => (
	<div className="share-async">
		<Share {...props} />
	</div>
)
